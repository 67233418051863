const ResetStateMixin = {
    data() {
        return {
            componentInitialData: {},
        };
    },
    created() {
        this.initGlobalMixin();
    },
    methods: {
        initGlobalMixin() {
            // Save the initial state of the component's data
            this.componentInitialData = { ...this.$data };
        },
        resetState() {
            // Reset the component's data to its initial state
            Object.assign(this.$data, this.componentInitialData);
        },
    },
};

export default ResetStateMixin;
