<template>
  <section class="pb-5">

  <p><b>Pattern Configuration</b></p>


    <div class="mt-2">
    <v-select v-model="form.keep_subsequent"
              class="my-3"
              :items="[{ text: 'Keep Subsequent Rotations', value: true }, { text: 'Purge Subsequent Rotations', value: false }]"
              label="Rotations Settings"
              @change="fetchPatternPreview"
              outlined
              required
     />
    </div>

    <v-select
        v-model="form.prolongation"
        :items="[{ text: 'Prolong Contract End Date', value: true }, { text: 'Keep Contract End Date', value: false }]"
        label="Prolongation Settings"
        @change="fetchPatternPreview"
        outlined
        required
    />

    <div v-if="form.prolongation" class="d-flex mt-5 gap-10">
      <div>
        <leben-date-picker v-if="form.prolongation" :disabled="true"  v-model="form.contract_end_old" label="Current Contract End date"></leben-date-picker>
      </div>
      <div>
        <leben-date-picker v-if="form.prolongation" v-model="form.contract_end_new" label="New Contract End date"></leben-date-picker>
      </div>
    </div>

    <v-select v-model="form.total_rotations"
              class="mt-2"
              :items="[{ text: 'Current and One Previous', value: 2 }, { text: 'Current and Two Previous', value: 3 }]"
              @change="fetchPatternPreview"
              label="Total Rotations"
              outlined
              required
    />

    <p><b>Pattern Preview</b></p>


    <v-card color=""  class=" my-5">

      <div v-for="rotation  in preview">
        <div class="mb-2 d-flex" v-if="rotation.contract_job">

      <job-name-label-display :job="rotation.contract_job" />
          <span class="ml-2">,<b>  {{rotation.duration}} Days</b> </span>
      </div>
        <v-divider class="my-2"/>
      </div>

    </v-card>

    <v-sheet class="text-right">
    <v-btn class="ml-auto" color="primary" @click="applyPattern">Submit</v-btn>
    </v-sheet>











  </section>
</template>
<script>
import LebenDatePicker from "@/views/components/LebenDatePicker.vue";
import contractRotations from "@/api/modules/contractRotations";
import JobNameLabelDisplay from "@/components/JobNameLabelDisplay.vue";
import {EventBus} from "@/bootstrap/EventBus";
import ResetStateMixin from "@/plugins/resetStateMixin";

export default {
  components: {JobNameLabelDisplay, LebenDatePicker},
  mixins: [ResetStateMixin],
  props: ['rotation',],
  name: 'ExtendWithPatternForm',
  data: function () {
    return {
      form :
          {
            rotation_id: null,
            contract_id: null,

            prolongation: false,

            contract_end_old : null,
            contract_end_new: null,
            total_rotations: null,

          },
      preview: [],
    }
  },
  created() {
    this.resetState();

    this.form.contract_id = this.rotation.contract.id;
    this.form.rotation_id = this.rotation.id;
    this.form.contract_end_old = this.rotation.contract.end_date;
    this.form.contract_end_new = this.rotation.contract.end_date;
  },
  methods: {
    fetchPatternPreview: function () {

     contractRotations.fetchPattern(this.form).then(res =>{
       this.preview = res.data;

     })
    },

    applyPattern: function () {
      this.form.jobs = this.patternJobs;

      contractRotations.applyPattern(this.form).then(res =>{
        console.log(res.data)
        console.log('pattern applied');

        this.$notify({
          title: 'Success',
          text: 'Pattern Applied.',
          type: 'success'
        });


        EventBus.$emit('rotation-update');

      })

    },
    beforeDestroy: function () {

      Object.assign(this.$data, this.$options.data.apply(this))
    }


  },
  computed:
      {
        patternJobs: function () {

          return this.preview.map(rotation => {
            return { id: rotation.contract_job_id, duration: rotation.duration}
          })
        }
      }


}


</script>
